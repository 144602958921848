<template>
  <div class="comment-index">
    <div class="comment-index-left">
      <div>
        <el-form ref="form" :model="submitForm">
          <el-form-item>
            <el-input
              v-model.trim="submitForm.fullCommentId"
              size="small"
              placeholder="请输入评论ID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              size="small"
              clearable
              v-model.trim="submitForm.parentId"
              placeholder="上级ID"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.fullPostId"
              clearable
              size="small"
              placeholder="请输入帖子ID"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.author"
              clearable
              size="small"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.subreddit"
              clearable
              size="small"
              placeholder="请输入社区名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.query"
              clearable
              size="small"
              placeholder="关键词"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              size="small"
              v-model.trim="submitForm.createBeginTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              size="small"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model.trim="submitForm.createEndTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width:100%;margin-top:10px; border-radius: 16px;"
          @click="searchFun"
        >
          查 询
        </el-button>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          近10条历史查询记录
        </div>
        <div class="left-list-title-b"></div>
        <ul>
          <li v-for="(item, i) in listData" :key="i">
            <span
              @click="selectitem(i)"
              class="history-words"
              :class="selectIndex == i ? 'select-obj' : ''"
            >
              {{ item | listFilters }}
            </span>
          </li>
        </ul>
        <no-data
          fontSize="12px"
          text="暂无历史查询记录"
          v-if="listData.length == 0"
        />
      </div>
    </div>
    <div class="comment-index-right">
      <totalAll
        v-if="contentList.length > 0"
        :text="'共检索到数据' + total + '条'"
      />
      <div
        class="right-child"
        v-infinite-scroll="fetchData"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="right-item" v-for="(item, i) in contentList" :key="i">
          <div class="item-title">
            <div>
              <img
                :src="
                  item.redditUser
                    ? item.redditUser.iconImg
                    : require('@/assets/imgs/p.png')
                "
                alt=""
                :onerror="noPic"
              />
              <span class="title-type">{{ item.author }}</span>
              <span class="title-type">
                {{ item.created }}
              </span>
              <span class="title-type">帖子ID：{{ item.fullPostId }}</span>
              <span class="title-type">上级ID：{{ item.parentId }}</span>
              <span class="title-type">所属社区:</span>
              <img
                :src="
                  item.redditSubreddit
                    ? item.redditSubreddit.iconImg
                    : require('@/assets/imgs/r.jpg')
                "
                alt=""
                :onerror="shequPic"
              />
              <span class="title-type">{{ item.subreddit }}</span>
            </div>
          </div>

          <div class="item-content-box">
            <div
              class="item-content-title"
              v-html="item.body"
              @click="getpopText(item)"
            ></div>
            <!-- <el-popover placement="bottom" popper-class="comment-popover">
              <div
                class="item-content"
                v-html="item.body"
                @click="getpopText(item)"
              ></div>
              <div
                slot="reference"
                class="item-content-title"
                v-html="item.body"
              ></div>
            </el-popover> -->
          </div>
          <div class="item-foot" style="margin-left:35px">
            <div>
              <img src="@/assets/imgs/i2.png" alt="" />
              <span>{{ item.score }}分</span>
            </div>

            <div style="margin-left: 18px">
              <span>原链接：</span>
              <a
                :href="'https://www.reddit.com' + item.permalink"
                target="_blank"
              >
                <span>reddit.com{{ item.permalink }}</span>
              </a>
            </div>
          </div>
        </div>
        <noMorePage :nomore="nomore" v-if="contentList.length > 0" />
      </div>
      <no-data v-if="contentList.length == 0" />
    </div>

    <!---// 点击详情 弹窗-->
    <el-dialog title="详情" :visible.sync="centerDialogVisible" width="40%">
      <p>{{ popText }}</p>
    </el-dialog>
  </div>
</template>

<script>
import { getCommentsByQuery } from '../../api/reddit'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/p.png') + '"',
      shequPic: 'this.src="' + require('@/assets/imgs/r.jpg') + '"',
      submitForm: {
        fullCommentId: '',
        fullPostId: '',
        author: '',
        subreddit: '',
        query: '',
        parentId: '',
        createBeginTime: '',
        createEndTime: ''
      },
      listData: [],
      selectIndex: null,
      contentList: [],

      popText: '',
      centerDialogVisible: false,

      // 当前页
      current: 1,
      // 每页记录数
      limit: 50,
      // 总记录数
      total: 0,
      scrollDisabled: false,
      nomore: true
    }
  },
  filters: {
    listFilters(obj) {
      if (obj) {
        for (let i in obj) {
          if (obj[i] && obj[i].trim() !== '') {
            return obj[i]
          }
        }
      }
    }
  },
  created() {
    this.getLocalStorageFun()
  },
  methods: {
    getpopText(row) {
      //console.log(row)
      //var newObg = Object.assign({}, row)
      this.popText = row.body
      this.centerDialogVisible = true
    },
    searchFun() {
      this.selectIndex = null
      this.current = 1
      this.contentList = []
      this.fetchData()
      this.localStorageFun()
      this.getLocalStorageFun()
    },
    selectitem(index) {
      this.current = 1
      this.contentList = []
      this.selectIndex = index
      let obj = this.listData[index]
      this.submitForm = Object.assign({}, obj)
      this.fetchData()
    },
    localStorageFun() {
      let arrString = localStorage.getItem('commentCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.isEmpty(this.submitForm)) {
            arr.push(this.submitForm)
          }
        } else {
          if (this.isEmpty(this.submitForm)) {
            arr.shift()
            arr.push(this.submitForm)
          }
        }
      } else {
        arr = []
        if (this.isEmpty(this.submitForm)) {
          arr.push(this.submitForm)
        }
      }
      localStorage.setItem('commentCommunity', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('commentCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.listData = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    },

    async fetchData() {
      try {
        this.listLoading = true
        this.scrollDisabled = true
        let obj = Object.assign(
          { pageIndex: this.current, pageSize: this.limit },
          this.submitForm
        )
        const data = await getCommentsByQuery(obj)

        this.total = data.all_count
        if (data.items.length > 0) {
          this.scrollDisabled = false
          this.current++
          this.contentList = this.contentList.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomore = false
          }
        } else {
          this.nomore = false
        }
      } finally {
        this.listLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.comment-index {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .comment-index-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border: 1px solid #e5e5e5;
    .left-list {
      height: calc(100% - 385px);
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      margin-top: 20px;
      .left-list-title {
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
        border-left: 4px solid #0079d3;
        padding-left: 10px;
      }
      .left-list-title-b {
        margin: 10px 0;
        border-bottom: 1px solid #fbf6f6;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 15px;
          .history-words {
            padding: 5px 10px;
            background: #efefef;
            border: 0px solid #000000;
            border-radius: 20px;

            font-size: 12px;
            font-weight: 300;
            color: #2a2a2a;
            cursor: pointer;
            user-select: none;
          }
          .select-obj {
            background: #ff4500;
            color: #ffffff;
          }
        }
      }
    }
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  .comment-index-right {
    width: calc(100% - 270px);
    height: 100%;
    position: relative;
    overflow: hidden;
    // border: 1px solid #e5e5e5;
    border-radius: 5px;

    .right-child {
      width: 100%;
      height: calc(100% - 37px);
      overflow: hidden;
      overflow-y: auto;
      background: #ffffff;
      margin-top: 5px;
    }
    .right-item {
      position: relative;
      width: 100%;
      background: #ffffff;
      // border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      // position: relative;

      .item-title {
        width: 100%;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        > div {
          align-self: center;
        }
        span {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
          vertical-align: middle;
        }
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
          vertical-align: middle;
          border-radius: 50%;
        }
        .title-type {
          margin: 0 10px;
        }
        .title-symbol {
          font-size: 22px;
          color: #ababab;
          margin: 0 5px;
        }
      }
      .item-sub {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        margin: 10px 0;
      }
      .item-content-box {
        .item-content-title {
          padding: 0 35px;
          font-size: 14px;
          color: #1a1a1b;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          cursor: pointer;
        }
        .item-content {
          width: 300px;
          height: 300px;
          overflow: hidden;
        }
      }

      .item-foot {
        margin-top: 20px;
        display: flex;
        img {
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
          font-size: 12px;
          margin-left: 5px;
          font-weight: 300;
          color: #898989;
        }
      }
    }
    .right-item::after {
      content: '';
      position: absolute;

      bottom: 0;
      left: 50%;
      height: 1px;
      width: 96.5%;
      background-color: #eeebe8;
      transform: translateX(-50%);
    }
    /* .right-item::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 25px;
      height: calc(100% - 40px);
      width: 1px;
      background-color: #eeebe8;
    } */
    .right-item-one::before,
    .right-item-one::after {
      width: 0;
      height: 0;
    }

    .content-none {
      font-size: 22px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #898989;
    }
  }
}
.comment-popover {
  width: 50%;
  max-height: 300px;
  overflow: auto;
  line-height: 25px;
  background: #f2f2f2;
  box-shadow: 1px 4px 8px rgb(0 0 0 / 40%);
  padding: 15px;
  border: 1px solid rgb(102, 102, 102);
}
</style>
